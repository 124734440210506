import { version } from '../../package.json';

export const baseUrl: string = 'https://app.harperjames.co.uk';

export const environment = {
  production: true,
  apiUrl: `${baseUrl}/api`,
  appVersion: `${version}-prod`,
  appInsightsConnectionString:
    'InstrumentationKey=018a25b1-994b-41cc-a119-84e03a8fa2c5;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/',

  config: {
    auth: {
      clientId: '34671b74-cf30-496e-98aa-6ddee50b9439',
      authority: 'https://login.microsoftonline.com/ff9c468c-4463-4262-a31d-5401f37e4b9d',
      validateAuthority: true,
      redirectUri: '/',
      postLogoutRedirectUri: '/logout',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    resources: {
      demoApi: {
        resourceUri: 'https://graph.microsoft.com/v1.0/me',
        resourceScope: 'api://34671b74-cf30-496e-98aa-6ddee50b9439/ReadUser', // here use the client id of the Web API you registered
      },
    },
    scopes: {
      loginRequest: ['user.read'],
    },
  },
};
